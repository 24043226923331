import ActiveToggleController from './active_toggle_controller';

const KEY_ENTER = 13;
const KEY_SPACE = 32;
const KEY_ESCAPE = 27;

export default class extends ActiveToggleController {
  static targets = [
    ...ActiveToggleController.targets,
    'dropdown',
    'container',
    'menu',
    'content',
    'backdrop',
  ];

  connect() {
    super.connect();

    this.itemTargets = this.contentTarget.querySelectorAll('.dropdown-item');
    this.bindDropdownItemTargets();
    this.bindTriggerTarget();
    this.bindBackdropTarget();
  }

  bindDropdownItemTargets() {
    this.itemTargets.forEach((item) => {
      if (!item.classList.contains('keep-open')) {
        item.addEventListener('click', () => {
          this.deactivate();
        })
      }
    });
  }

  bindTriggerTarget() {
    this.triggerTarget.addEventListener('keydown', (e) => {
      if ([KEY_ENTER, KEY_SPACE].includes(e.keyCode)) {
        this.toggle();
      }
      if ([KEY_ESCAPE].includes(e.keyCode) && this.activeValue) {
        e.preventDefault();
        this.deactivate();
      }
    })
  }

  bindBackdropTarget() {
    this.backdropTarget.addEventListener('click', () => super.deactivate());
  }

  get toggles() {
    return [
      ...this.toggleTargets,
      this.dropdownTarget,
      this.containerTarget,
      this.triggerTarget,
      this.backdropTarget,
    ];
  }
}
