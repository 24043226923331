import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'tab',
    'button',
  ];
  static values = {
    currentTabIndex: { type: Number, default: 0 },
  }

  connect() {
    this.buttonTargets.forEach((buttonTarget, index) => {
      buttonTarget.addEventListener('click', () => {
        this.changeTab(buttonTarget.dataset.tabIndex ?? index);
      });
    });
    this.changeTab(parseInt((new URLSearchParams(window.location.search)).get('tab'), 10))
    this.setHrefSearch();
  }

  changeTab(index) {
    this.currentTabIndexValue = index || 0;
    insertUrlParam('tab', this.currentTabIndexValue)
    this.displayTab();
  }

  displayTab() {
    this.tabTargets.forEach((tab, index) => {
      if (index == this.currentTabIndexValue) {
        tab.classList.remove(tab.dataset.hiddenClass || 'is-hidden-touch');
      } else {
        tab.classList.add(tab.dataset.hiddenClass || 'is-hidden-touch');
      }
    });
    this.buttonTargets.forEach((button, index) => {
      const buttonIndex = button.dataset.tabIndex ?? index;

      if (buttonIndex == this.currentTabIndexValue) {
        button.classList.add(button.dataset.activeClass || 'is-white');
        button.classList.remove(button.dataset.inactiveClass || 'is-ghost');
      } else {
        button.classList.remove(button.dataset.activeClass || 'is-white');
        button.classList.add(button.dataset.inactiveClass || 'is-ghost');
      }
    });
  }

  setHrefSearch() {
    this.tabTargets.forEach((tab, index) => {
      tab.querySelectorAll('.pagination-container a').forEach((link) => {
        const searchParams = new URLSearchParams(link.search);

        searchParams.set('tab', index);
        link.search = searchParams.toString();
      });
    });
  }
}

function insertUrlParam(key, value) {
  if (history.pushState) {
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set(key, value);
    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${searchParams.toString()}`;

    window.history.pushState({ path: newUrl }, '', newUrl);
  }
}
