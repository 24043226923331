import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';
import { formatHours } from '../shared/util/format';
import i18n from '../../i18n.js';

export default class extends Controller {
  static targets = [
    'formTemplate',
    'form',
    'table',
    'editButton',
    'saveButton',
    'name',
    'id',
    'hours',
    'rowTemplate',
    'addButton',
    'progress',
    'currentHours',
    'totalHours',
    'hiddenInputTemplate',
    'tableHeader',
    'tooManyHoursText'
  ];
  static values = {
    categoryId: Number,
    sectorId: Number,
    schoolyearId: Number,
    percentage: Number,
  };

  connect() {
    this.subHourBanks = gon.sub_hour_banks[this.categoryIdValue] ?? [];
    this.updateHours();
    this.switchMode(false)
    this.buildTable();
    this.buildForm();
    this.sortable = new Sortable(this.formTarget, {
      handle: '.drag-handle',
    });
  }

  updateHours() {
    const hourBankKey = [this.categoryIdValue, this.sectorIdValue, this.schoolyearIdValue].join(',');
    const hourBank = window.gon.hour_banks[hourBankKey];

    if (!hourBank) {
      return;
    }

    const valid = this.hoursTargets.every((target) => target.value) && this.nameTargets.every((target) => target.value);

    this.saveButtonTarget.disabled = !valid;

    const currentHours = this.hoursTargets.reduce((acc, hourTarget) => (Number(hourTarget.value) || 0) + acc, 0);
    const totalHours = Math.round((hourBank.hours * this.percentageValue) / 100);

    setAttributes(this.progressTarget, { max: totalHours, value: currentHours });
    this.currentHoursTarget.textContent = formatHours(currentHours);
    this.totalHoursTarget.textContent = formatHours(totalHours);
    const excessHours = currentHours - totalHours;

    this.tooManyHoursTextTarget.textContent = i18n.template(gon.t.too_many_hours, { hours: formatHours(excessHours) });
    this.tooManyHoursTextTarget.parentElement.hidden = excessHours <= 0;
  }

  deleteRow(event) {
    document.dispatchEvent(new CustomEvent('open_confirm_modal', { detail: { onConfirm: () => this.confirmDeleteRow(event) } }))
  }

  confirmDeleteRow(event) {
    event.target.parentElement.remove()
    this.updateHours();
  }

  edit() {
    this.switchMode(true)
    if (this.formTarget.children.length == 0) {
      this.addLine()
    }
    window.dispatchEvent(new CustomEvent('editing-subform'));
  }

  save() {
    this.switchMode(false)
    this.subHourBanks = this.nameTargets.map((name) => ({
      name: name.value,
    }));
    this.hoursTargets.forEach((hours, index) => {
      this.subHourBanks[index].hours = hours.value;
    });
    this.idTargets.forEach((id, index) => {
      this.subHourBanks[index].id = id.value;
    });
    this.buildTable();
    window.dispatchEvent(new CustomEvent('saved-subform'));
  }

  enableEdit() {
    this.editButtonTarget.disabled = false;
  }
  disableEdit() {
    this.editButtonTarget.disabled = true;
  }

  switchMode(editing) {
    this.editButtonTarget.hidden = editing;
    this.tableTarget.hidden = editing;
    this.tableHeaderTarget.hidden = editing;
    this.formTarget.hidden = !editing;
    this.saveButtonTarget.hidden = !editing;
    this.addButtonTarget.hidden = !editing;
  }

  addLine(_event, id, name, hours) {
    const newLine = this.formTemplateTarget.content.cloneNode(true);

    newLine.querySelector('[data-sub-hour-bank-form-target="id"]').value = id ?? '';
    newLine.querySelector('[data-sub-hour-bank-form-target="name"]').value = name ?? '';
    newLine.querySelector('[data-sub-hour-bank-form-target="hours"]').value = hours ?? '';
    newLine.querySelector('[data-sub-hour-bank-form-target="name"]').addEventListener('input', () => {
      this.updateHours();
    });
    newLine.querySelector('[data-sub-hour-bank-form-target="hours"]').addEventListener('input', (event) => {
      if (Number(event.target.value) < 0) {
        event.target.value = 0;
      }
      this.updateHours();
    });
    newLine.querySelector('[data-action="click->sub-hour-bank-form#deleteRow"]').addEventListener('click', (e) => {
      this.deleteRow(e);
    });


    this.formTarget.appendChild(newLine);
    this.updateHours();
  }

  buildTable() {
    while (this.tableTarget.lastElementChild) {
      this.tableTarget.removeChild(this.tableTarget.lastElementChild);
    }
    this.tableHeaderTarget.hidden = true;
    this.subHourBanks.forEach((subHourBank, index) => {
      this.tableHeaderTarget.hidden = false;
      const newElement = this.rowTemplateTarget.content.cloneNode(true);

      newElement.querySelector('[data-field="name"]').textContent = subHourBank.name;
      newElement.querySelector('[data-field="hours"]').textContent = (
        Number.isInteger(subHourBank.hours) ? Math.round(subHourBank.hours) : subHourBank.hours
      );
      this.tableTarget.appendChild(newElement);

      const hiddenInput = this.hiddenInputTemplateTarget.content.cloneNode(true);

      setAttributes(
        hiddenInput.querySelector('input'),
        {
          name: 'sub_hour_bank[]',
          value: JSON.stringify(
            {
              'id': subHourBank.id,
              'category_id': this.categoryIdValue,
              'name': subHourBank.name,
              'hours': subHourBank.hours,
              'order': index
            }
          )
        }
      )
      this.tableTarget.appendChild(hiddenInput);
    });
  }

  buildForm() {
    this.subHourBanks.forEach((subHourBank) => {
      this.addLine(null, subHourBank.id, subHourBank.name, subHourBank.hours);
    });
  }
}

function setAttributes(element, attributes) {
  Object.keys(attributes).forEach((key) => {
    element.setAttribute(key, attributes[key]);
  })
}
