export function isTabPressed(event) {
  return event.key === 'Tab';
}

class TrapFocus {
  constructor(containerElement, service) {
    const focusableElements = 'button, [href], [tabindex]';

    this.focusableContent = Array.from(containerElement.querySelectorAll(focusableElements));

    this.focusedItem = null;
    this._abortController = new AbortController();
    this.trapped = false;
    this.pausedStateCallbacks = [];
    this.resumedStateCallbacks = [];
    this.service = service;

    this.initKeydownEventListener();
  }

  initKeydownEventListener() {
    document.addEventListener('keydown', (e) => {
      if (!this.trapped || this.released) {
        return;
      }

      if (isTabPressed(e)) {
        this.keyboardControlled = true;
        this.focusNextElement(e);
      }
    }, { signal: this.abortController.signal });

    document.addEventListener('click', () => {
      if (!this.trapped || this.released) {
        return;
      }

      this.keyboardControlled = false;
    }, { signal: this.abortController.signal });
  }

  focusNextElement(e) {
    e?.preventDefault();
    const nextItem = this.findNextElementToFocus(e);

    if (!nextItem) {
      return;
    }

    this.focusableContent.forEach((element) => element.classList.remove('focus-visible'));
    this.focusedItem = nextItem;
    this.focusedItem?.focus();
    if (document.body.classList.contains('keyboard-focused')) {
      this.focusedItem.classList.add('focus-visible');
    }
  }

  findNextElementToFocus(e = null) {
    if (this.focusableContent.length < 1) {
      return null;
    }

    if (this.focusedItem === null) {
      if (e?.shiftKey) {
        return this.lastFocusableElement;
      }

      return this.firstFocusableElement;
    }

    const direction = e?.shiftKey ? -1 : 1;
    let idx = (this.focusableContent.indexOf(this.focusedItem) + direction) % this.focusableContent.length;

    if (idx < 0) {
      idx = this.focusableContent.length - 1;
    }

    return this.focusableContent[idx];
  }

  trap(keyboardControlled = false) {
    if (this.released) {
      return;
    }

    this.trapped = true;
    document.activeElement.blur();
    this.keyboardControlled = keyboardControlled;
    if (keyboardControlled) {
      this.focusNextElement();
    }
  }

  resume(keyboardControlled = false) {
    if (this.released || this.trapped) {
      return;
    }

    this.resumedStateCallbacks.forEach((callback) => {
      callback();
    });
    this.keyboardControlled = keyboardControlled;
    if (this.keyboardControlled) {
      this.focusedItem?.focus();
    }
    this.trapped = true;
  }

  pause() {
    if (this.released || !this.trapped) {
      return;
    }

    this.trapped = false;
    this.pausedStateCallbacks.forEach((callback) => {
      callback();
    });
  }

  release(keyboardControlled = false, resumePrevious = true) {
    if (this.released) {
      return;
    }

    this.abortController.abort();
    if (resumePrevious) {
      this.service.releaseFocus(this, keyboardControlled);
    }
    this.trapped = false;
    this.released = true;
  }

  onResume(callback) {
    this.resumedStateCallbacks.push(callback);
  }

  onPause(callback) {
    this.pausedStateCallbacks.push(callback);
  }

  get abortController() {
    return this._abortController;
  }

  get firstFocusableElement() {
    if (this.focusableContent.length < 1) {
      return null;
    }

    return this.focusableContent[0];
  }

  get lastFocusableElement() {
    if (this.focusableContent.length < 1) {
      return null;
    }

    return this.focusableContent[this.focusableContent.length - 1];
  }
}

export default class TrapFocusService {
  static _focusStack = [];

  static trapFocus(containerElement, keyboardControlled = false) {
    this.currentFocusTrap?.pause();

    const focusTrap = new TrapFocus(containerElement, this);

    this._focusStack.push(focusTrap);
    focusTrap.trap(keyboardControlled);

    return focusTrap;
  }

  static releaseFocus(focusTrap, keyboardControlled = false) {
    if (focusTrap === this.currentFocusTrap) {
      this._focusStack.pop();
      this.currentFocusTrap?.resume(keyboardControlled);
    } else {
      const index = this._focusStack.indexOf(focusTrap);

      if (index > -1) {
        this._focusStack.splice(index, 1);
      }
    }
  }

  static releaseAll() {
    this._focusStack.forEach((focusTrap) => {
      focusTrap.release(false, false);
    });
    this._focusStack = [];
  }

  static get currentFocusTrap() {
    if (this._focusStack?.length > 0) {
      return this._focusStack[this._focusStack.length - 1];
    }

    return null;
  }
}
