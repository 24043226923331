import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['userField', 'profileField'];

  connect() {
    $(this.userFieldTarget).on('select2:select', () => this.enableFields());
    $(this.userFieldTarget).select2({
      language: new URLSearchParams(window.location.search).get('locale'),
      ajax: {
        url: '/search/users',
        dataType: 'json',
        delay: 250,
        data(params) {
          return {
            q: params.term,
            page: params.page,
          };
        },
        processResults(data, params) {
          const paramsPage = params.page || 1;

          return {
            results: data.items.map((user) => ({ id: user.id, text: `${user.full_name} ${user.email}` })),
            pagination: {
              more: (paramsPage * data.per_page) < data.total_count,
            },
          };
        },
      },
      minimumInputLength: 3,
      width: '100%',
    });
    this.enableFields();
  }

  enableFields() {
    this.profileFieldTarget.disabled = !this.userFieldTarget.value;
    this.profileFieldTarget.setAttribute('data-user-id', this.userFieldTarget.value);
  }
}
