import { Controller } from '@hotwired/stimulus';

export default class ActiveToggleController extends Controller {
  static targets = ['trigger', 'toggle'];
  static values = {
    active: Boolean,
  }

  connect() {
    this.activeValue = false;
    this.triggers.forEach((target) => {
      target.addEventListener('click', () => {
        this.toggle();
      });
    })
  }

  toggle() {
    if (this.activeValue) {
      this.deactivate();
    } else {
      this.activate();
    }
  }

  activate() {
    if (this.activeValue) {
      return;
    }

    this.activeValue = true;

    this.toggles.forEach((target) => {
      target.classList.add('is-active');
    })
  }

  deactivate() {
    if (!this.activeValue) {
      return;
    }

    this.activeValue = false;

    this.toggles.forEach((target) => {
      target?.classList.remove('is-active');
    })
  }

  get triggers() {
    return [...this.triggerTargets];
  }

  get toggles() {
    return [...this.toggleTargets];
  }
}
