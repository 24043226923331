import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['field', 'nextButton'];

  connect() {
    this.editingSubform = false
    this.validate();
  }

  setEditing() {
    this.editingSubform = true
    this.validate()
  }

  setNotEditing() {
    this.editingSubform = false;
    this.validate()
  }

  validate() {
    const isValid = this.fieldTargets.filter((field) => window.getComputedStyle(field).visibility !== 'hidden')
      .every((field) => this.validateField(field));

    this.nextButtonTarget.disabled = this.editingSubform || !isValid;
  }

  validateField(field) {
    const validationType = field.attributes.getNamedItem('data-validation')?.value;

    if (!validationType) {
      return true;
    } else if (validationType === 'required') {
      return Boolean(field.value);
    }
    console.error('unknown validation', validationType);
  }
}
