import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'show',
    'hide',
  ];
  static values = {
    active: { type: Boolean, default: true },
  }

  connect() {
    this.hideElements();
  }

  toggle() {
    this.activeValue = !this.activeValue;
    this.hideElements();
  }

  hideElements() {
    this.hideTargets.forEach((hideTarget) => {
      hideTarget.hidden = this.activeValue;
    });
    this.showTargets.forEach((showTarget) => {
      showTarget.hidden = !this.activeValue;
    });
  }
}
