import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'form'];

  selectProfile({ target }) {
    this.inputTarget.value = target.dataset.profileId;
    this.formTarget.submit();
  }
}
