import { Controller } from '@hotwired/stimulus';
import Toastify from 'toastify-js';

export default class extends Controller {
  connect() {
    let messages = $(this.element).data('flash-messages');

    if (messages == null) {
      return;
    }

    if (!Array.isArray(messages)) {
      messages = [messages];
    }

    if (Array.isArray(messages)) {
      messages.forEach((message) => {
        if (!message) {
          return;
        }

        Toastify({
          text: `${message}`,
          duration: 10000,
          close: true,
          stopOnFocus: true,
          className: $(this.element).data('toastify-class'),
          gravity: 'bottom',
          position: 'right',
        }).showToast();
      });
    }
  }
}
