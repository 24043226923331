import { Controller } from '@hotwired/stimulus';
import select2 from '../../bulma/init/select2';

export default class extends Controller {
  static targets = ['userField', 'wantedTeamField'];

  connect() {
    select2();
    $(this.wantedTeamFieldTarget).select2({
      language: new URLSearchParams(window.location.search).get('locale'),
      width: '100%',
    });
    $(this.userFieldTarget).select2({
      language: new URLSearchParams(window.location.search).get('locale'),
      ajax: {
        url: '/search/users',
        dataType: 'json',
        delay: 250,
        data(params) {
          return {
            q: params.term,
            page: params.page,
          };
        },
        processResults(data, params) {
          const paramsPage = params.page || 1;

          return {
            results: data.items.map((user) => ({ id: user.id, text: `${user.full_name} ${user.email}` })),
            pagination: {
              more: (paramsPage * data.per_page) < data.total_count,
            },
          };
        },
      },
      minimumInputLength: 3,
      width: '100%',
    });
  }
}
