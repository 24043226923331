import { Controller } from '@hotwired/stimulus';
import Toastify from 'toastify-js';

export default class extends Controller {
  static values = {
    content: String
  }

  connect() {
    this.context.element.addEventListener('click', () => {
      navigator.clipboard.writeText(this.contentValue);
      Toastify({
        text: window.gon.t.copied,
        gravity: 'bottom',
        duration: 1000,
        close: true,
        stopOnFocus: true,
        className: 'flash-notice',
        position: 'right',
      }).showToast();
    });
  }
}
