import ActiveToggleController from './active_toggle_controller';
import TrapFocusService from '../shared/util/trapFocus';
import breakpoints from '../bulma/utils/css_breakpoints';

export default class extends ActiveToggleController {
  static targets = [
    ...ActiveToggleController.targets,
    'sideNav',
    'backdrop'
  ]

  connect() {
    window.addEventListener('resize', () => this.onScreenResize());
    this.onScreenResize();
  }

  close() {
    this.focusTrap?.release();
    super.deactivate();
  }

  open() {
    super.activate();
    this.focusTrap = TrapFocusService.trapFocus(this.sideNavTarget);
  }

  toggle() {
    if (this.activeValue) {
      this.close();
    } else {
      this.open();
    }
  }

  onScreenResize() {
    if (window.innerWidth > breakpoints.touch) {
      if (this.activeValue) {
        this.close();
      }
    }
  }

  get toggles() {
    return [
      ...super.toggles,
      this.sideNavTarget,
      this.backdropTarget,
    ]
  }
}
