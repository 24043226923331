import { Controller } from '@hotwired/stimulus';
import breakpoints from '../bulma/utils/css_breakpoints';
import { toggleHidden } from '../shared/util';

export default class extends Controller {
  static targets = ['step', 'nextButton', 'previousButton'];

  connect() {
    this.currentStepIndex = 0;
    this.mobile = false;
    this.numberOfMobileSteps = new Set(
      this.stepTargets.map((target) =>
        target.attributes.getNamedItem('data-step-mobile').value)
    ).size;
    this.numberOfDesktopSteps = new Set(
      this.stepTargets.map((target) =>
        target.attributes.getNamedItem('data-step-desktop').value)
    ).size;
    window.addEventListener('resize', () => this.onScreenResize());
    this.onScreenResize();
  }

  onScreenResize() {
    if (window.innerWidth <= breakpoints.mobile) {
      if (!this.mobile) {
        this.mobile = true;
        this.currentStepIndex = Number(this.convertDesktopStepToMobileStep());
      }
    } else if (this.mobile) {
      this.mobile = false;
      this.currentStepIndex = Number(this.convertMobileStepToDesktopStep());
    }
    this.showCurrentSteps();
    this.setButtonText();
  }

  setButtonText() {
    this.nextButtonTarget.textContent = this.nextStepText();
    this.previousButtonTarget.textContent = this.previousStepText();
  }

  showCurrentSteps() {
    const attribute = this.mobile ? 'data-step-mobile' : 'data-step-desktop';

    this.stepTargets.forEach((stepTarget) => {
      toggleHidden(stepTarget, stepTarget.attributes.getNamedItem(attribute).value != this.currentStepIndex);
    });
  }

  nextStep() {
    if (this.currentStepIndex === this.numberOfSteps() - 1) {
      return;
    }
    event.preventDefault();
    this.changeStep(1);
  }

  previousStep() {
    if (this.currentStepIndex === 0) {
      history.back();
    } else {
      this.changeStep(-1);
    }
  }

  changeStep(direction) {
    this.currentStepIndex += direction;
    this.showCurrentSteps();
    this.setButtonText();
    window.dispatchEvent(new CustomEvent('form-step-changed'));
    setImmediate(() => document.querySelector('main').parentElement.scrollTo(0, 0));
  }

  previousStepText() {
    if (this.currentStepIndex === 0) {
      return window.gon.t.cancel;
    }

    return window.gon.t.back;
  }

  nextStepText() {
    if (this.currentStepIndex === this.numberOfSteps() - 1) {
      return window.gon.t.save;
    }

    return window.gon.t.next;
  }

  convertMobileStepToDesktopStep() {
    return this.stepTargets.find((step) =>
      step.attributes.getNamedItem('data-step-mobile').value == this.currentStepIndex
    ).attributes.getNamedItem('data-step-desktop').value;
  }

  convertDesktopStepToMobileStep() {
    return this.stepTargets.find((step) =>
      step.attributes.getNamedItem('data-step-desktop').value == this.currentStepIndex
    ).attributes.getNamedItem('data-step-mobile').value;
  }

  numberOfSteps() {
    return this.mobile ? this.numberOfMobileSteps : this.numberOfDesktopSteps;
  }
}
