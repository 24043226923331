import { Controller } from '@hotwired/stimulus';
import TrapFocusService from '../shared/util/trapFocus';
import WindowScrollingService from '../shared/util/window_scrolling_service';
import { toggleActive } from '../shared/util';

const EVENT_MODAL_ACTIVE = 'modalActive'
const EVENT_MODAL_INACTIVE = 'modalInactive'

export default class extends Controller {
  static targets = ['modalWindow'];

  static values = {
    openEvent: String,
    openOnLoad: Boolean
  }

  initialize() {
    if (this.openEventValue) {
      document.addEventListener(this.openEventValue, (event) => {
        this.open(event)
      })
    }
  }

  connect() {
    this.isOpen = this.modalWindowTarget.classList.contains('is-active');

    const esc = (e) => {
      if (e.keyCode === 27) {
        this.close(null, true);
      }
    };

    document.addEventListener('keydown', esc);

    if (this.openOnLoadValue) {
      setImmediate(() => {
        this.open();
      });
    }
  }

  disconnect() {
    this.close();
  }

  open(event) {
    this.openEvent = event;
    const params = event?.params || {}
    const { propagation = true } = params

    if (this.isOpen) {
      return;
    }

    event?.preventDefault();
    this.isOpen = true;
    this.toggleModalActive(true);
    this.trapFocusInModal();

    if (propagation) {
      this.dispatch('open', { detail: {} });
    }

    WindowScrollingService.lock();
  }

  close(event) {
    const params = event?.params || {}
    const { propagation = true } = params

    if (this.isOpen) {
      this.isOpen = false;
      this.toggleModalActive(false);

      if (propagation) {
        this.dispatch('closed', { detail: { openEvent: this.openEventValue } });
      }

      this.releaseFocusFromModal(false);
      WindowScrollingService.unlock();
      TrapFocusService.releaseAll();

      if (this.openTrigger) {
        this.openTrigger.focus();
      }

      if (event.params.confirm && this.openEvent.detail.onConfirm) {
        this.openEvent.detail.onConfirm();
      }
    }
  }

  toggleModalActive(isActive) {
    if (this.hasModalWindowTarget) {
      const event = new Event(isActive ? EVENT_MODAL_ACTIVE : EVENT_MODAL_INACTIVE);

      document.dispatchEvent(event);
      toggleActive(this.modalWindowTarget, isActive);
    }
  }

  trapFocusInModal() {
    this.focusTrap = TrapFocusService.trapFocus(this.modalWindowTarget);
  }

  releaseFocusFromModal(keyboardControlled = false) {
    if (this.focusTrap) {
      this.focusTrap.release(keyboardControlled);
      this.focusTrap = null;
    }
  }
}
