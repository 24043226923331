import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'modal',
    'form',
  ]

  connect() {
    const modalController = this.application.getControllerForElementAndIdentifier(this.element, 'modal');

    this.formTarget.addEventListener('submit', () => {
      if (this.formTarget.checkValidity()) {
        modalController.close();
      }
    })
  }
}
