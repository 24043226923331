import { toggleAttribute, toggleHidden } from '../shared/util';
import { Controller } from '@hotwired/stimulus';
import { formatHours } from '../shared/util/format';
import i18n from '../../i18n';

export default class extends Controller {
  static targets = [
    'summary',
    'endDateToggle',
    'startDateField',
    'endDateField',
    'subHourBankField',
    'categoryField',
    'addedHoursTag',
    'hoursField',
    'subHourBankProgressBar',
    'subHourBankName',
    'hourBankName',
    'hourBankProgressBar',
    'subHourBankTooManyHours',
    'hourBankTooManyHours',
    'subHourBankFraction',
    'hourBankFraction',
    'addingTooManyHoursText',
    'addingSummaryText',
  ];
  static values = {
    profileId: Number,
  };

  connect() {
    const vm = this;

    $(this.subHourBankFieldTarget).select2({
      language: new URLSearchParams(window.location.search).get('locale'),
      ajax: {
        url: '/search/sub_hour_banks',
        dataType: 'json',
        delay: 250,
        data(params) {
          return {
            'category_id': vm.categoryFieldTarget.value,
            'profile_id': vm.profileIdValue,
            'q': params.term,
            'page': params.page,
          };
        },
        processResults(data, params) {
          const paramsPage = params.page || 1;

          return {
            results: data.items.map((subHourBank) => ({
              'id': subHourBank.id,
              'text': `${subHourBank.name}`,
            })),
            pagination: {
              more: (paramsPage * data.per_page) < data.total_count,
            },
          };
        },
      },
      placeholder: gon.t.prompt,
      width: '100%',
    }).on('change', () => this.fillSummary());
    $(this.categoryFieldTarget).on('change', (event) => this.categoryChanged(event))
    this.updateFieldsState();
    this.fillSummary();
  }

  categoryChanged() {
    $(this.subHourBankFieldTarget).val(null).trigger('change');
    $(this.subHourBankFieldTarget).prop('disabled', false);
  }

  updateFieldsState() {
    const checked = this.endDateToggleTarget.checked;

    toggleHidden(this.endDateFieldTarget, !checked);

    this.updateFieldRequiredAttribute(this.startDateFieldTarget, checked);
    this.updateFieldRequiredAttribute(this.endDateFieldTarget, checked);

    if (!checked) {
      const input = this.endDateFieldTarget.querySelector('input');

      input.value = null;
      input.dispatchEvent(new Event('change'));
    }
  }

  updateFieldRequiredAttribute(fieldTarget, required) {
    const inputElement = fieldTarget.querySelector('input');

    toggleAttribute(inputElement, 'required', required);
    inputElement.dispatchEvent(new Event('required'));
  }

  fillSummary() {
    if (!this.hoursFieldTarget.value || !this.subHourBankFieldTarget.value) {
      this.summaryTarget.hidden = true;

      return;
    }
    this.summaryTarget.hidden = false;
    this.addedHoursTagTarget.textContent = `+${formatHours(this.hoursFieldTarget.value)}`;
    const subHourBank = gon.sub_hour_banks[this.subHourBankFieldTarget.value];
    const subHourBankValue = subHourBank.done_hours + Number(this.hoursFieldTarget.value);
    const hourBank = gon.hour_banks[subHourBank.category_id];
    const hourBankDoneHours = Object.values(gon.sub_hour_banks)
      .filter((innerSubHourBank) => innerSubHourBank.category_id === subHourBank.category_id)
      .reduce((acc, val) => (acc + val.done_hours), 0) + Number(this.hoursFieldTarget.value);

    this.subHourBankNameTarget.textContent = subHourBank.name;
    this.hourBankNameTarget.textContent = subHourBank.category_name;
    this.addingTooManyHoursTextTarget.hidden = subHourBankValue - subHourBank.hours <= 0;
    this.addingTooManyHoursTextTarget.textContent = i18n.template(
      gon.t.adding_too_many_hours,
      {
        'sub_hour_bank_name': subHourBank.name,
        'hours': formatHours(subHourBankValue - subHourBank.hours),
      },
    );

    this.addingSummaryTextTarget.textContent = i18n.template(
      gon.t.adding_summary,
      {
        'sub_hour_bank_name': subHourBank.name,
        'hours': formatHours(this.hoursFieldTarget.value),
        'hour_bank_name': subHourBank.category_name,
      },
    );

    this.setProgressBarAndText(
      this.subHourBankProgressBarTarget,
      this.subHourBankTooManyHoursTarget,
      this.subHourBankFractionTarget,
      subHourBankValue,
      subHourBank.hours,
    )

    this.setProgressBarAndText(
      this.hourBankProgressBarTarget,
      this.hourBankTooManyHoursTarget,
      this.hourBankFractionTarget,
      hourBankDoneHours,
      hourBank.hours,
    )
  }

  setProgressBarAndText(progressBarTarget, textTarget, fractionTarget, value, max) {
    const surplusHours = value - max;

    progressBarTarget.value = value;
    progressBarTarget.max = max;

    fractionTarget.querySelector('.worked-hours').textContent = formatHours(value, ':');
    fractionTarget.querySelector('.planned-hours').textContent = formatHours(max, ':');
    textTarget.parentElement.hidden = surplusHours <= 0;
    textTarget.textContent = i18n.template(gon.t.too_many_hours, { hours: formatHours(surplusHours) });
  }
}
