import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    url: String
  }

  to() {
    window.location.href = this.urlValue;
  }

  back() {
    window.history.back();
  }
}
