import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    event: String,
  }

  connect() {
    this.element.onclick = () => {
      document.dispatchEvent(new Event(this.eventValue))
    }
  }
}
