import { Controller } from '@hotwired/stimulus';
import { formatHours } from '../shared/util/format';

export default class extends Controller {
  static targets = ['sectorId', 'schoolyearId', 'percentage', 'hourbank', 'totalHours', 'categoryHour', 'subHourBank'];

  connect() {
    setImmediate(() => this.updateHours());
  }

  updateHours() {
    let formValid = true;
    let totalHours = 0;

    const percentage = Math.max(Math.min(Number(this.percentageTarget.value), 100), 0);

    this.subHourBankTargets.forEach((subHourBankFormTarget) => {
      subHourBankFormTarget.setAttribute('data-sub-hour-bank-form-sector-id-value', this.sectorIdTarget.value);
      subHourBankFormTarget.setAttribute('data-sub-hour-bank-form-schoolyear-id-value', this.schoolyearIdTarget.value);
      subHourBankFormTarget.setAttribute('data-sub-hour-bank-form-percentage-value', percentage);
      this.application.getControllerForElementAndIdentifier(subHourBankFormTarget, 'sub-hour-bank-form').updateHours();
    });

    window.gon.categories.forEach((category) => {
      const hourBankKey = [category.id, this.sectorIdTarget.value, this.schoolyearIdTarget.value].join(',');
      const hourBank = window.gon.hour_banks[hourBankKey];
      let hourText;

      if (hourBank === undefined || Number.isNaN(percentage)) {
        formValid = false;
      } else {
        const hourTotal = (percentage * hourBank.hours) / 100;

        hourText = formatHours(hourTotal);
        totalHours += hourTotal;
      }

      const categoryTarget = this.categoryHourTargets.find((target) =>
        target.attributes['data-category-id'].value == category.id
      );

      categoryTarget.textContent = hourText;
    });

    this.totalHoursTarget.textContent = formatHours(totalHours);
    this.hourbankTarget.hidden = !formValid;
  }
}
