export default {
  translateWithCount(translations, count) {
    if (gon.locale == 'en') {
      if (count == 1) {
        return translations.one;
      }

      return translations.other;
    } else if (gon.locale == 'fr') {
      if (count <= 1) {
        return translations.one;
      }

      return translations.other;
    }
  },
  template(translation, keys) {
    return Object.entries(keys)
      .reduce((translation, [key, text]) => translation.replace(`%{${key}}`, text), translation);
  },
}
