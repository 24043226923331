function getCssVariable(variableName) {
  return getComputedStyle(document.documentElement).getPropertyValue(`${variableName}`);
}

const breakpointKeys = [
  'mobile',
  'tablet',
  'desktop',
  'widescreen',
  'fullhd',
  'touch',
  'until-widescreen',
  'until-fullhd'
]

const breakpoints = Object.fromEntries(breakpointKeys.map((breakpoint) =>
  [breakpoint, parseInt(getCssVariable(`--breakpoint-${breakpoint}`), 10)]
))

export default breakpoints
